
const projects = [
    {
        title: 'Budgetary',
        img: 'budgetary.png',
        description: 'A budgeting app designed with the "envelope system" in mind. It has not reached public usability yet, but that should be changing in the next few months.',
        link: 'https://www.youtube.com/watch?v=UwnH2W_lYy0'
    },
    {
        title: '9Heroes Tower Defense',
        img: 'nineHeroes.png',
        description: 'A turn-based tower defense game where you can move your towers. It is actively hosted on homelightarchive.com, but it lacks a dedicated tutorial and the login is bugged.',
        link: 'https://www.youtube.com/watch?v=uvl8G5uwjHE'
    },
    {
        title: 'Doomwood RPG',
        img: 'doomwood.png',
        description: 'A simple web-based roguelike with turn-based combat. It is my first web-based project, replicating a game I designed for a graphing calculator.',
        link: 'https://spencer.hicken.com/games/doomwood'
    }
]

export default projects;