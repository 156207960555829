import React from 'react';
import { useEffect } from 'react';
import '../css/Home.css'
import Profile from '../img/profilePic.png'
import projects from '../data/projects'
import ProjectSlide from './ProjectSlide';
import {  NavLink, Navigate, useNavigate } from 'react-router-dom';


const Home = (props) => {
    let projectIndex = 0;
    let autoplay = null
    const navigate = useNavigate();
    
    
    const createSlides = projects.map((project, i) => {
        return <ProjectSlide
        key={i}    
        project={project}
        style={{transform: `translateX(${i * 100}%)`}}
        >
        </ProjectSlide>
    })
    
    
    const nextSlide = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        projectIndex++;
        if (projectIndex >= createSlides.length) projectIndex = 0;
        changeSlide();
    }
    
    const prevSlide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        projectIndex--;
        if (projectIndex < 0) projectIndex = (createSlides.length - 1);
        changeSlide();
    }
    
    const quickSlide = (e, i) => {
        e.preventDefault();
        e.stopPropagation();
        projectIndex = i;
        changeSlide();
    }
    
    const changeSlide = () => {
        document.querySelector('.current')?.classList.remove('current');
        document.querySelectorAll('.quickBtn')[projectIndex]?.classList.add('current');
        document.querySelectorAll('.slide')?.forEach((slide, idx) => {
            slide.style.transform = `translateX(${100 * (idx - projectIndex)}%)`;
        });
    }
    
    const startAuto = () => autoplay = setInterval(() => nextSlide(null), 10000);
    const stopAuto = () => clearInterval(autoplay);
    
    useEffect(() => {
        startAuto();
        return () => stopAuto();
    }, []);
    
    let createQuickBtns = [
        <button key={0} onClick={e => quickSlide(e, 0)} className='quickBtn current'></button>
    ];
    
    for (let i = 1; i < createSlides.length; i++) {
        createQuickBtns.push(
            <button key={i} onClick={e => quickSlide(e, i)} className='quickBtn'></button>
        );
    }

  return (<main className='home'>
    <div className='hero flex'>
        <div>
            <h2>Heyyo!</h2>
            <h1>I'm Spencer Hicken</h1>
            <p>A frontend web developer, excited to create cool stuff.</p>
        </div>
        <img className='heroPic' src={Profile} alt="" />
    </div>
    <div 
        className="carousel"
        onClick={(e)=>{
            e.preventDefault();
            navigate('/portfolio');
        }}
    >
        {createSlides}
        <div className="buttonContainer">
            <button onClick={e => prevSlide(e)} className="btn">&lt;</button>
            {createQuickBtns}
            <button onClick={e => nextSlide(e)} className="btn">&gt;</button>
        </div>


    </div>
  </main>);
};

export default Home;