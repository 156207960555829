import React from 'react';
import projects from '../data/projects'
import ProjectCard from './ProjectCard';
import '../css/Portfolio.css'

const Portfolio = (props) => {
  let projectCards = projects.map((project, i) => {
    return <ProjectCard key={i} project={project}></ProjectCard>
  });
  return (
    <main className='portfolio'>
      {projectCards}
    </main>
  );
};

export default Portfolio;