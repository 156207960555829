import './css/App.css';
import Header from './components/Header'
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';

function App() {
  return (<>
    <Header />
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/about' element={<About/>}/>
      <Route exact path='/portfolio' element={<Portfolio/>}/>
      {/* <Route exact path='/contact' element={<Contact/>}/> */}
      <Route path='*' element={<Home/>}/>
    </Routes>
    <Footer />
  </>);
}

export default App;
