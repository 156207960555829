import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Header.css';

const Header = (props) => {
  // Your component code goes here
  return (
    <header>
        <NavLink to={'/'} >Home</NavLink>
        <NavLink to={'/about'} >About</NavLink>
        <NavLink to={'/portfolio'} >Portfolio</NavLink>
        {/* <NavLink to={'/contact'} >Contact</NavLink> */}
        <a href={'./reference/resume.pdf'} target='_blank' >Resume</a>
    </header>
  );
};

export default Header;