import React from 'react';
import { useState } from 'react';

const ProjectCard = (props) => {
  let {project} = props;
  const [hover, setHover] = useState(false)

	const dataBox = (<div>
        <h3>{project.title}</h3>
        <p>{project.description}</p>
    </div>)

    const onMouseEnter = () => {
        setHover(true)
    }
    
    const onMouseLeave = () => {
        setHover(false)
    }
  // Your code here
  return (
    <a href={project.link}>
    <div className='projectCard' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {hover ? dataBox : ''}
        <img src={`img/${project.img}`} alt={project.title} />
    </div>
    </a>
  );
};

export default ProjectCard;