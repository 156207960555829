import React from 'react';

const SkillCard = (props) => {
  let {skill} = props;
  // Your code here
  return (
    <div className='skill'>
        <p>
        {skill.name}
        </p>
        {skill.rating}/5
    </div>
  );
};

export default SkillCard;