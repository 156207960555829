import React from 'react';

// import budgetary from '../img/budgetary.png'
// import nineHeroes from '../img/9heroes.png'
// import doomwood from '../img/doomwood.png'


const ProjectSlide = (props) => {
  let {project} = props;
  // Your code here
  return (
    <div 
    className='slide'
    style={props.style}
    >
        <img src={(`../img/${project.img}`)} alt="" />
    </div>
  );
};

export default ProjectSlide;