import React from 'react';
import '../css/About.css'
import firstPic from '../img/about1.jpg'
import secondPic from '../img/about2.jpg'
import SkillCard from './SkillCard';

const About = (props) => {
  // Your component code goes here
  const skills = [
    {name:'JavaScript', rating:'4'},
    {name:'React', rating:'3'},
    {name:'HTML5 & CSS', rating:'3'},
    {name:'NodeJS', rating:'2'},
    {name:'SQL (PostgreSQL)', rating:'3'},
  ].map((skill, i) => {
    return <SkillCard skill={skill}></SkillCard>
  })

  return (
    <main className='about'>
      <div className='section'>
        {skills}
      </div>
      <div className='section'>
        <img src={firstPic} alt="Primary Profile" className='abtPic'/>
        <div className='content'>
          <h3>About me</h3>
          <p>Well, I'm a web developer. That's what you expected, right? I've graduated from DevMountain Boot Camp, with a specialization in website creation. </p>
          <p>I've always loved making things, from coming up with ways to play the Pokemon card game by myself to creating my own turn-based RPG on a graphing calulator. Be it words, cards, a pencil and paper, or a calculator, I used the tools I had to make something I considered cool and worthwhile.</p>
          <p>As I matured, left the home I grew up in, and moved toward the career field, I looked for a way to create things for a living. Many of my family members are in the web development field, and one of them encouraged me to try it out. So, I learned the toolset of web development, and I can say, it definitely is where I want to be.</p>
        </div>
      </div>
      <div className='section'>
        <div className='content'>
          <h3>My Spare Time</h3>
          <p>First off... does it even exist? Joking aside, here are a few of my hobbies that I somehow have enough time to enjoy.</p>
          <ul>
            <li>Reading (Mostly fantasy)</li>
            <li>Anime (Isekais and Low Fantasy)</li>
            <li>Strategy Videogames (Fire Emblem and card battlers, mostly)</li>
            <li>Game design (Both board games and digital games)</li>
            <li>Writing (Reading books wasn't enough. I'm writing my own)</li>
            <li>Magic the Gathering (Among other trading card games)</li>
            <li>Fencing (Yes, the sport with swords)</li>
          </ul>
        </div>
        <img src={secondPic} alt="Secondary Profile" className='abtPic'/>

      </div>
    </main>
  );
};

export default About;